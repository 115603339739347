<template>
  <div>
    <Navbar />

    <Breadcrumb />

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>

      <Sidebar />

      <vue-page-transition id="content" name="fade-in-right">
        <router-view></router-view>
      </vue-page-transition>
    </div>

    <Footer />
  </div>
</template>

<script>
import $ from "jquery";

const Navbar = () => import("@/components/dashboard/Navbar");
const Breadcrumb = () => import("@/components/dashboard/Breadcrumb");
const Sidebar = () => import("@/components/dashboard/Sidebar");
const Footer = () => import("@/components/dashboard/Footer");

// LAYOUT FILE FOR DASHBOARD
export default {
  name: "Dashboard",
  components: {
    Navbar,
    Breadcrumb,
    Sidebar,
    Footer,
  },

  mounted() {
    setTimeout(() => {
      $(document).ready(function () {
        App.init(); // eslint-disable-line
      });
    }, 500);
  },
};
</script>

<style src="@/assets/css/plugins.css"></style>
<style src="@/assets/css/dashboard/dash_1.css"></style>

<style>
.flatpickr-calendar {
  z-index: 99999999999999999 !important;
}

.component-card_1 {
  width: auto !important;
}
.card-body {
  padding: 10px !important;
}
.buttons-columnVisibility {
  color: #025430;
  background-color: white;
  margin: 1px;
}
.active {
  background-color: #025430 !important;
  color: white !important;
}
.buttons-html5 {
  background-color: #049a58 !important;
  border-color: #ffffff !important;
  margin: 5px !important;
  border-radius: 30px !important;
}

.btn-secondary {
  background-color: #049a58 !important;
  border-color: #ffffff !important;
  margin: 5px !important;
  border-radius: 30px !important;
}

.page-item.active {
  background-color: #049a58 !important;
  border-color: #049a58 !important;
}
.page-item.active .page-link {
  background-color: #049a58 !important;
  border-color: #049a58 !important;
}

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: #b9dacb !important;
}

.text-muted {
  color: #393d41 !important;
  font-size: 11px !important;
}

.media-body h6 {
  font-weight: bold !important;
}
</style>
